import { isModeratorOrHigher } from '@/utilities';

export default function ({ redirect, store, app, query }) {
  if (app.$auth.loggedIn) {
    if (isModeratorOrHigher(store.state.role) && !store.state.masquerading) {
      return redirect('/admin/submissions');
    }
    return redirect('/', query);
  }
}
