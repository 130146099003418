import UPDATE_USER from '@/graphql/mutations/UpdateUser';
import { TOOL_TYPE, TOOL_STATUS, ONBOARDING_STEP } from '@/utilities/constants';

export default {
  initOnboarding({ commit, rootGetters }, stepKey) {
    commit('setCurrentStepKey', stepKey);
    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      residentialAddress: { country } = {},
    } = rootGetters['directory-person/userDetails'];
    const affiliateUserId = rootGetters.affiliateUserId;
    const couponLatestReferral = rootGetters['coupon/couponLatestReferral'];

    commit('setPayload', {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      country,
      affiliateUserId,
      couponCode: couponLatestReferral,
    });
    if ([ONBOARDING_STEP.FAMILY, ONBOARDING_STEP.ASSETS].includes(stepKey)) {
      commit('setSelectedVaultItemsIds', []);
    }
  },

  async loadToolAndVaultItems({ dispatch, rootState, rootGetters }) {
    await dispatch(
      'tool/createToolIfNotExists',
      {
        ownerId: rootState.userId,
        type: TOOL_TYPE.ONBOARDING_NEEDS_ASSESSMENT,
      },
      {
        root: true,
      }
    );
    const onboardingNeedsAssessmentTool =
      rootGetters['tool/onboardingNeedsAssessmentTool'];
    await dispatch(
      'vault-item/getOnboardingNeedsAssessmentVaultItems',
      {
        ids: onboardingNeedsAssessmentTool?.vaultItemIds || [],
      },
      {
        root: true,
      }
    );
  },
  async saveName({ commit, state, dispatch }) {
    commit('setLoading', true);
    await dispatch(
      'directory-person/updateUserDirectoryPerson',
      state.payload,
      { root: true }
    );
    commit('setLoading', false);
    dispatch('nextStep');
  },
  async saveDobAndAffiliateData({ commit, state, dispatch, rootState }) {
    commit('setLoading', true);
    if (state.payload.affiliateUserId || state.payload.couponCode) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          userId: rootState.userId,
          affiliateUserId: state.payload.affiliateUserId || undefined,
          couponCode: state.payload.couponCode || undefined,
        },
      });
      dispatch('setAffiliateUserId', state.payload.affiliateUserId, {
        root: true,
      });
      dispatch('coupon/setCouponLatestReferral', state.payload.couponCode, {
        root: true,
      });
    }
    await dispatch(
      'directory-person/updateUserDirectoryPerson',
      state.payload,
      { root: true }
    );
    commit('setLoading', false);
    dispatch('nextStep');
  },
  async saveCountry({ commit, state, dispatch }) {
    commit('setLoading', true);
    await dispatch(
      'directory-person/updateUserDirectoryPerson',
      state.payload,
      { root: true }
    );
    commit('setLoading', false);
    dispatch('nextStep');
  },
  async saveVaultItems(
    { commit, rootGetters, rootState, dispatch, state, getters },
    { selectedIds, vaultItems }
  ) {
    commit('setLoading', true);
    const onboardingNeedsAssessmentTool =
      rootGetters['tool/onboardingNeedsAssessmentTool'];

    const onboardingNeedsAssessmentToolVaultItems = await dispatch(
      'vault-item/getVaultItems',
      {
        ids: onboardingNeedsAssessmentTool.vaultItemIds,
      },
      {
        root: true,
      }
    );

    const itemsToUpsert = vaultItems
      .filter(
        ({ id, createIfDoesNotExist, key, type }) =>
          (createIfDoesNotExist || selectedIds.includes(id)) && !!key && !!type
      )
      .map(({ id, name, key, type }) => ({
        name,
        key,
        type,
        value: selectedIds.includes(id),
      }))
      .map((item) => {
        const id = onboardingNeedsAssessmentToolVaultItems.find(
          ({ key, type }) => key === item.key && type === item.type
        )?.id;
        return { ...item, id };
      });

    const tool = await dispatch(
      'tool/upsertVaultItemsByTool',
      {
        toolId: onboardingNeedsAssessmentTool.id,
        vaultItems: itemsToUpsert,
      },
      {
        root: true,
      }
    );
    await dispatch(
      'vault-item/getOnboardingNeedsAssessmentVaultItems',
      {
        ids: tool.vaultItemIds,
      },
      {
        root: true,
      }
    );
    if (getters.currentStepKey === ONBOARDING_STEP.FAMILY) {
      commit('setLoading', false);
      dispatch('nextStep');
    } else {
      await dispatch(
        'tool/updateTool',
        {
          id: onboardingNeedsAssessmentTool.id,
          status: TOOL_STATUS.COMPLETED,
        },
        {
          root: true,
        }
      );
      commit('setLoading', false);

      const willTiersSliceTwo = rootState.featureFlags['will-tiers-slice-two'];
      const willTierTwoEnabled =
        rootGetters['affiliate/features'].willTierTwoEnabled;

      if (willTiersSliceTwo && willTierTwoEnabled) {
        this.$router.push(state.steps[ONBOARDING_STEP.INTRODUCTION].slug);
      } else {
        this.$router.push(state.steps[ONBOARDING_STEP.FINISH].slug);
      }
    }
  },
  nextStep({ commit, getters, state }) {
    let nextStepKey = getters.nextStepKey;
    if (!nextStepKey) {
      nextStepKey = ONBOARDING_STEP.FINISH;
    }

    commit('setCurrentStepKey', nextStepKey);
    const nextSlug = state.steps[nextStepKey].slug;
    this.$router.push(nextSlug);
  },
  setSelectedVaultItemsIds({ commit }, ids) {
    commit('setSelectedVaultItemsIds', ids);
  },
};
