import { getField, updateField } from 'vuex-map-fields';
import { metaArrayToObject } from '@/utilities';

function defaults(o = {}) {
  return Object.assign(
    {
      full_name: '',
      address_street: '',
      address_suburb: '',
      address_state: '',
      address_postcode: '',
      address_country: '',
      email: '',
      date_of_birth: '',
      identifier: 'dob',
    },
    o
  );
}

export const state = () => ({
  id: null,
  type: 'person',
  fields: defaults(),
  showPersonPanel: false,
  identifierRequirements: null,
});

export const getters = {
  id: (state) => state.id,
  type: (state) => state.type,
  fields: (state) => state.fields,
  showPersonPanel: (state) => state.showPersonPanel,
  identifierRequirements: (state) => state.identifierRequirements,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  type(state, value) {
    state.type = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  showPersonPanel(state, value) {
    state.showPersonPanel = value;
  },
  identifierRequirements(state, value) {
    state.identifierRequirements = value;
  },
  updateField,
};

export const actions = {
  addPerson({ commit }, type = 'person') {
    commit('id', null);
    commit('type', type);
    commit('fields', defaults({ is_over_18: type !== 'child' }));
    commit('showPersonPanel', true);
  },
  editPerson({ commit }, { person, type = 'person' }) {
    commit('id', person.id);
    commit('type', type);
    commit('fields', defaults(metaArrayToObject(person.meta)));
    commit('showPersonPanel', true);
  },
  setShowPersonPanel({ commit }, value) {
    commit('showPersonPanel', value);
  },
  setIdentifierRequirements({ commit }, value) {
    commit('identifierRequirements', value);
  },
};
