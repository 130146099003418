import { mapActions, mapGetters } from 'vuex';

import ADD_PET_GUARDIAN_MUTATION from '@/graphql/mutations/AddPetGuardian';
import ADD_PET_MUTATION from '@/graphql/mutations/AddPet';
import REMOVE_PET_GUARDIAN_MUTATION from '@/graphql/mutations/RemovePetGuardian';
import REMOVE_PET_MUTATION from '@/graphql/mutations/RemovePet';
import UPDATE_PET_MUTATION from '@/graphql/mutations/UpdatePet';

export default {
  name: 'MixinsApolloPets',
  data() {
    return {
      ADD_PET_GUARDIAN_MUTATION,
      ADD_PET_MUTATION,
      REMOVE_PET_GUARDIAN_MUTATION,
      REMOVE_PET_MUTATION,
      UPDATE_PET_MUTATION,
    };
  },
  computed: {
    ...mapGetters('will', ['pets']),
  },
  methods: {
    ...mapActions('pet', ['addPet', 'editPet']),
    ...mapActions('will', ['setPets', 'getPetsData']),
    async addPetGuardian(pet, contactId) {
      const { data } = await this.$apollo.mutate({
        mutation: this.ADD_PET_GUARDIAN_MUTATION,
        variables: {
          petId: pet.id,
          guardianDirectoryPersonId: contactId,
          willId: this.willId,
        },
      });
      data.addPetGuardian && (await this.refreshPet(data.addPetGuardian));
    },
    async removeAllPets() {
      await Promise.all(
        this.pets.map((pet) => {
          return this.removePet(pet);
        })
      );
    },
    async removePet(pet) {
      try {
        await this.$apollo.mutate({
          mutation: this.REMOVE_PET_MUTATION,
          variables: {
            id: pet.id,
            willId: this.willId,
          },
        });

        await this.setPets(this.pets.filter((m) => m.id !== pet.id));
      } catch (error) {
        console.error(error);
        await this.getPetsData();
      }
    },
    async removePetGuardian(pet) {
      const { data } = await this.$apollo.mutate({
        mutation: this.REMOVE_PET_GUARDIAN_MUTATION,
        variables: {
          petId: pet.id,
          willId: this.willId,
        },
      });
      data.removePetGuardian && (await this.refreshPet(data.removePetGuardian));
    },
    async refreshPet(pet) {
      await this.setPets(this.pets.map((p) => (p.id === pet.id ? pet : p)));
    },
  },
};
