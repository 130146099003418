import VAULT_ITEM_QUERY from '@/graphql/queries/VaultItems.gql';

export default {
  async getVaultItems({ commit, rootGetters }, { keys, ids }) {
    const userId = rootGetters?.userId;
    if (!userId) {
      commit('setVaultItems', []);
      return [];
    }
    const { data: vaultItems } =
      await this.app.apolloProvider.defaultClient.query({
        query: VAULT_ITEM_QUERY,
        variables: {
          ownerId: userId,
          keys: keys || [],
          ids: ids || [],
        },
      });

    const items = vaultItems?.vaultItems ?? [];
    commit('setVaultItems', items);
    return items;
  },
  async getOnboardingNeedsAssessmentVaultItems({ dispatch, commit }, { ids }) {
    const items = await dispatch('getVaultItems', { ids });
    commit('setOnboardingNeedsAssessmentVaultItems', items);
  },
};
