import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';
import flushPromises from 'flush-promises';
import cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { sign } from 'jsonwebtoken';
import isDate from 'lodash/isDate';
import { USER_ROLES } from '@/utilities/users/form-constants';

export function metaArrayToObject(arr, defaults = {}) {
  if (isArray(arr)) {
    const obj = { ...defaults };
    arr.forEach((item) => {
      let value = item.value;
      switch (value) {
        case 'true':
          value = true;
          break;
        case 'false':
          value = false;
          break;
        default:
          break;
      }
      obj[item.key] = value;
    });
    return obj;
  } else {
    return defaults;
  }
}

export function objectToMetaArray(obj = {}) {
  const arr = [];
  const optional = [
    'phone_number',
    'funeral_location',
    'funeral_note',
    'asset_note',
    'name_middle',
    'alt_name_middle',
    'pet_care_fund',
  ];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (!isArray(value) && !isObject(value)) {
      if (value === null) {
        arr.push({
          key,
          value,
        });
      } else {
        value = toString(value);
        if (value.length) {
          arr.push({
            key,
            value,
          });
        } else if (optional.includes(key)) {
          arr.push({
            key,
            value: null,
          });
        }
      }
    }
  });
  return arr;
}

export function isStrictBoolean(value) {
  return [true, false].includes(value);
}

export function isNullish(value) {
  return [null, undefined].includes(value);
}

export function dobIsUnderAge(dob, age) {
  if (!age) {
    return false;
  }
  if (isDateIsoFormat(dob)) {
    dob = convertIsoDateToLegacyDate(dob);
  }
  if (!isDateLegacyFormat(dob)) {
    return false;
  }
  const vals = dob.split('/');
  const date = new Date(vals[2], vals[1] - 1, vals[0]);
  const today = new Date();
  const birthday = new Date(
    today.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  const hadBirthday = today >= birthday;
  const years = today.getFullYear() - date.getFullYear();
  const ageAtLastBirthday = hadBirthday ? years : years - 1;
  return ageAtLastBirthday < age;
}

export function isDateOver18(dateString) {
  if (isDateIsoFormat(dateString)) {
    dateString = convertIsoDateToLegacyDate(dateString);
  }
  if (!isDateLegacyFormat(dateString)) {
    return false;
  }
  const vals = dateString.split('/');
  const dob = new Date(vals[2], vals[1] - 1, vals[0]);
  const today = new Date();
  const birthday = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
  const hadBirthday = today >= birthday;
  const years = today.getFullYear() - dob.getFullYear();
  const age = hadBirthday ? years : years - 1;
  return age >= 18;
}

export function isDateInFuture(dateString) {
  if (isDateIsoFormat(dateString)) {
    dateString = convertIsoDateToLegacyDate(dateString);
  }
  if (!isDateLegacyFormat(dateString)) {
    return false;
  }
  const vals = dateString.split('/');
  const date = new Date(vals[2], vals[1] - 1, vals[0]);
  const today = new Date();
  return date > today;
}

export function formatError(message) {
  return message.replace('GraphQL error: ', '');
}

export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function dashIfFalsy(value) {
  return value || '—';
}

export function yesIfTruthy(b) {
  if (typeof b === 'boolean') {
    return b ? 'Yes' : 'No';
  }
  return '–';
}

export function formatAUD(dollars) {
  return Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  }).format(dollars);
}

export function isDateEpochFormat(value) {
  return typeof +value === 'number' && value !== null && value !== undefined;
}

export function convertEpochDateToIsoFormat(value) {
  const date = new Date(+value);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

export function isDateIsoFormat(value) {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
  return isoDatePattern.test(value);
}

export function isIsoDateValid(value) {
  if (!isDateIsoFormat(value)) {
    return false;
  }
  const [year, month, day] = value.split('-').map((splitValue) => +splitValue);
  const date = new Date(Date.UTC(year, month - 1, day));
  return (
    date.getUTCFullYear() === year &&
    date.getUTCMonth() === month - 1 &&
    date.getUTCDate() === day
  );
}

export function convertIsoDateToLegacyDate(value) {
  if (isDateIsoFormat(value)) {
    return value.split('-').reverse().join('/');
  }
  return null;
}

export function isDateIsoDateTimeFormat(value) {
  const match = value.match(
    /^(?<parseable>\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}) \+\d{4} UTC$/
  );
  return match ? match.groups.parseable : false;
}

export function isDateLegacyFormat(value) {
  const legacyDatePattern = /^(\d{2})\/(\d{2})\/(\d{4})/gm;
  return legacyDatePattern.test(value);
}

export function convertIsoDateTimeToIsoDate(value) {
  if (isDateIsoDateTimeFormat(value)) {
    return value.split(' ')[0];
  }
  return null;
}

export function convertLegacyDateToIsoFormat(value) {
  if (isDateLegacyFormat(value)) {
    return value.split('/').reverse().join('-');
  }
  return null;
}
export function tryGetFromLocalStorage(key, defaultValue = false) {
  try {
    const item = window.localStorage.getItem(key);
    if (item === null) {
      return defaultValue;
    }
    if (item === 'false') {
      return false;
    } else if (item === 'true') {
      return true;
    }
    return defaultValue;
  } catch {
    return defaultValue;
  }
}

export function trySetLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch {}
}

export function getPersonFullName(directoryPerson) {
  if (!directoryPerson) return '';

  const { firstName, middleName, lastName } = directoryPerson;

  return [firstName?.trim(), middleName?.trim(), lastName?.trim()]
    .filter((name) => name)
    .join(' ');
}

export function getPersonFullAltName(directoryPerson) {
  if (!directoryPerson) return '';

  const { altFirstName, altMiddleName, altLastName } = directoryPerson;

  return [altFirstName?.trim(), altMiddleName?.trim(), altLastName?.trim()]
    .filter((name) => name)
    .join(' ');
}

export function getUserFullAddress(willMetaObject) {
  return [
    willMetaObject.address_street?.trim(),
    willMetaObject.address_suburb?.trim(),
    [
      willMetaObject.address_state?.trim(),
      willMetaObject.address_postcode?.trim(),
    ]
      .filter((part) => part)
      .join(' '),
  ]
    .filter((part) => part)
    .join(', ');
}

export function isBeneficiarySplitValid(value) {
  if (Number.isNaN(Number(value)) || Number(value) === 0) {
    return false;
  }
  const strValue = value.toString();
  if (strValue.startsWith('.')) {
    return false;
  }
  if ([...strValue].some((char) => ['-', '+', 'e', ','].includes(char))) {
    return false;
  }
  let [integer, decimal] = strValue.toString().trim().split('.');
  integer = integer || '';
  decimal = decimal || '';
  if (decimal.length > 2) {
    return false;
  }
  if (integer === '100') {
    if (!decimal || Number(decimal) === 0) {
      return true;
    }
  }
  return integer.length < 3;
}

export function jsonToBase64(json) {
  return Buffer.from(JSON.stringify(json)).toString('base64');
}

export function base64ToJson(base64) {
  return JSON.parse(Buffer.from(base64, 'base64').toString('ascii'));
}

export function getAnonymousProfile() {
  const anonymousProfileBase64 = cookies.get(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile'
  );
  if (!anonymousProfileBase64 || anonymousProfileBase64.trim().length === 0) {
    return null;
  }
  try {
    return base64ToJson(anonymousProfileBase64);
  } catch (e) {
    console.error(e.message);
  }
  return null;
}

export function retrieveUtmQueryParameters(queryParams) {
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];

  const utmKvps = utmKeys
    .map((key) => [key, queryParams[key] || null])
    .filter(([_key, value]) => value != null);

  if (utmKvps.length === 0) {
    return undefined;
  }

  return Object.fromEntries(utmKvps);
}

export function saveAnonymousProfile({
  utm,
  couponCode,
  affiliateSlug,
  referralCharity,
}) {
  const existingProfile = getAnonymousProfile() || {};
  cookies.set(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile',
    jsonToBase64({
      id: existingProfile.id || cookies.get('launchdarkly_id') || uuid(),
      utm: utm || existingProfile.utm || {},
      referral_coupon: couponCode || existingProfile.referral_coupon,
      affiliate_slug: affiliateSlug,
      referral_charity: referralCharity || existingProfile.referral_charity,
    }),
    {
      path: '/',
      domain: '.safewill.com',
      expires: 14,
    }
  );
}

export function encodePayloadForUrl(payload) {
  return encodeURIComponent(window.btoa(JSON.stringify(payload)));
}

export function decodePayloadFromUrl(queryParam) {
  return JSON.parse(window.atob(decodeURIComponent(queryParam)));
}

export function getBaseUrl() {
  const location = window.location;
  return location.protocol + '//' + location.host;
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function throwToFalse(fn) {
  try {
    return fn();
  } catch {
    return false;
  }
}

export async function pollUntilTruthy(
  predicateFn,
  retries = 30,
  sleepMs = 200
) {
  let value = await throwToFalse(predicateFn);
  for (let i = 0; i < retries && !value; i++) {
    await sleep(sleepMs);
    value = await throwToFalse(predicateFn);
  }
  return value;
}

export function isModeratorOrHigher(role) {
  return (
    role === 'MODERATOR' ||
    role === 'ADMIN' ||
    role === 'PARTNERSHIPS_ADMIN' ||
    role === 'SUPER_ADMIN'
  );
}

export function isSuperAdmin(role) {
  return role === 'SUPER_ADMIN';
}

export function isAdminOrHigher(role) {
  return (
    role === 'ADMIN' || role === 'SUPER_ADMIN' || role === 'PARTNERSHIPS_ADMIN'
  );
}

export function isPartnershipsAdminOrHigher(role) {
  return role === 'PARTNERSHIPS_ADMIN' || role === 'SUPER_ADMIN';
}

export function getSubordinateRolesByRole(role) {
  switch (role) {
    case 'SUPER_ADMIN':
      return [
        'SUPER_ADMIN',
        'PARTNERSHIPS_ADMIN',
        'ADMIN',
        'MODERATOR',
        'PARTNERSHIP',
        'CONSUMER',
      ];
    case 'PARTNERSHIPS_ADMIN':
      return ['PARTNERSHIP', 'CONSUMER'];
    case 'ADMIN':
      return ['CONSUMER'];
    default:
      return [];
  }
}

export function getSubordinateRoleOptionsByRole(role) {
  const subordinateRoles = getSubordinateRolesByRole(role);
  return USER_ROLES.filter((role) => subordinateRoles.includes(role.value));
}

export function hexToRgb(hex) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

export function isValidDateString(dateString) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return dateString.match(regEx) != null;
}

export function signJwt(data) {
  return sign(data, undefined, { algorithm: 'none' });
}

export function calculateTotalDistribution(distributions) {
  const total = distributions.reduce((agg, distribution) => {
    return agg + Number(distribution);
  }, 0);
  return Number(total.toFixed(2));
}

export function recursiveRemoveKey(object, deleteKey) {
  if (!object) {
    return;
  }
  delete object[deleteKey];
  Object.values(object).forEach((val) => {
    if (typeof val !== 'object') return;
    recursiveRemoveKey(val, deleteKey);
  });
}

export function toggleObjectInArray(
  objectToToggle,
  arrayToUpdate,
  propertyToCompare
) {
  if (
    !propertyToCompare ||
    !objectToToggle ||
    !objectToToggle[propertyToCompare]
  ) {
    return arrayToUpdate;
  } else if (!arrayToUpdate) {
    return [objectToToggle];
  } else {
    const itemExistsInArray =
      arrayToUpdate?.length &&
      arrayToUpdate.some(
        (arrayItem) =>
          arrayItem[propertyToCompare] === objectToToggle[propertyToCompare]
      );
    if (itemExistsInArray) {
      return arrayToUpdate.filter((arrayItem) => {
        return (
          arrayItem[propertyToCompare] !== objectToToggle[propertyToCompare]
        );
      });
    } else {
      return [...arrayToUpdate, objectToToggle];
    }
  }
}

export function parseJwtTokenAndReturnPayload(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function shortFileName(fileName, characterCount) {
  const characters = characterCount || 20;
  if (!fileName) {
    return '';
  } else if (fileName.length <= characters) {
    return fileName;
  }
  const half = Math.floor(characters / 2);
  return `${fileName.substr(0, half)}…${fileName.substr(
    fileName.length - half
  )}`;
}

export function fileIcon(fileName) {
  const extension =
    fileName && fileName.includes('.')
      ? fileName.substring(fileName.lastIndexOf('.') + 1)
      : null;
  switch (extension) {
    case 'pdf':
      return 'pi-file-pdf';
    case 'png':
    case 'jpg':
      return 'pi-image';
    default:
      return 'pi-file';
  }
}

export function toTitleCase(text) {
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function getPathWithoutLocale(pathName, localeCodes) {
  const [, firstPathElement, ...rest] = pathName.split('/');
  if (localeCodes?.includes(firstPathElement)) {
    return '/' + rest.join('/');
  }
  return pathName;
}

export function parseDate(date) {
  let parsed = date;
  if (!isDate(parsed)) {
    parsed = new Date(parsed);
    if (Number.isNaN(parsed.getTime())) {
      parsed = new Date(parseInt(date, 10));
    }
  }
  return parsed;
}

export function is24HoursFromNow(date) {
  const now = new Date();
  const then = parseDate(date);
  const diff = (now.getTime() - then.getTime()) / 1000;
  return diff < 86400;
}

export function isNumeric(value) {
  return !isNaN(value - parseFloat(value));
}

export function formatPrice(price, convertZero = true) {
  if (price === 0 && convertZero) return 'Free';
  const dollars = price / 100;
  return isNaN(dollars)
    ? '-'
    : dollars.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: dollars === Math.round(dollars) ? 0 : 2,
      });
}

export async function flushAll() {
  await flushPromises();
  jest.runAllTimers();
  await flushPromises();
}

export function isValidBusinessNumber(businessNumber) {
  if (!businessNumber) return false;
  const businessNumberPattern = /^[\d -]*\d[\d -]*$/;
  const digitCount = businessNumber.replace(/[^\d]/g, '').length;
  return (
    (digitCount === 11 || digitCount === 13) &&
    businessNumberPattern.test(businessNumber)
  );
}

export function formatCurrency(value) {
  const currency = value + '';
  const decimal = currency.split('.')[1] || '';
  return decimal.length < 2 ? parseFloat(currency).toFixed(2) : currency;
}

export function enrichCauseData(cause) {
  if (!cause) return null;
  const enrichedData = {
    ...cause,
    charities:
      cause.charities?.map((charity) => ({
        ...charity,
        meta: isArray(charity.meta)
          ? metaArrayToObject(charity.meta)
          : charity.meta,
      })) ?? [],
  };
  recursiveRemoveKey(enrichedData, '__typename');

  return enrichedData;
}

export function extractErrorMessages(error) {
  const errorsMessages = [];
  if (error.graphQLErrors?.[0]?.extensions?.errors) {
    error.graphQLErrors[0].extensions.errors.forEach((error) => {
      const messages = error.message.split(',');

      if (messages.length > 1) {
        errorsMessages.push(...messages.map((message) => message.trim()));
      } else {
        errorsMessages.push(error.message);
      }
    });

    return errorsMessages;
  }

  if (error?.message) {
    errorsMessages.push(error.message);
  }

  return errorsMessages.map((m) => m.replace('GraphQL error: ', ''));
}

export function joinTextWithAnd(textArray) {
  if (textArray?.length === 0) return '';
  if (!textArray?.length) return '';
  if (textArray.length === 1) return textArray[0];
  const last = textArray.pop();
  return `${textArray.join(', ')} and ${last}`;
}

export function filterNullish(value) {
  if (Array.isArray(value)) {
    return value.filter((item) => !isNullish(item));
  } else if (typeof value === 'object' && value !== null) {
    return Object.fromEntries(
      Object.entries(value).filter(([_key, val]) => !isNullish(val))
    );
  } else {
    return value;
  }
}
