import { getField, updateField } from 'vuex-map-fields';

const defaults = (o = {}) => {
  return Object.assign(
    {
      name: '',
      address: '',
      referral: false,
    },
    o
  );
};

export const state = () => ({
  id: null,
  fields: defaults(),
  showCharityPanel: false,
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  showCharityPanel: (state) => state.showCharityPanel,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  showCharityPanel(state, value) {
    state.showCharityPanel = value;
  },
  updateField,
};

export const actions = {
  addCharity({ commit }) {
    commit('id', null);
    commit('fields', defaults());
    commit('showCharityPanel', true);
  },
  setShowCharityPanel({ commit }, value) {
    commit('showCharityPanel', value);
  },
};
