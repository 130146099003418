export default {
  orders: (state) => state.orders,
  latestOrder: (state) => state.orders[0],
  purchasedProductCodes: (state) =>
    state.orders
      .filter(({ status }) => status === 'SUCCESSFUL')
      .flatMap(({ orderItems }) => orderItems.map(({ product }) => product)),
  latestPurchasedProductCodes: (_state, getters) =>
    getters.latestOrder?.orderItems.map(({ product }) => product) ?? [],
  isProductPurchased: (_state, getters) => (code) => {
    return getters.purchasedProductCodes.includes(code);
  },
  isLatestProductPurchased: (_state, getters) => (code) => {
    return getters.latestPurchasedProductCodes.includes(code);
  },
};
