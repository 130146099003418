export default {
  setSubmissionsPageIndex(state, value) {
    state.submissionsPageIndex = value < 0 ? 0 : value;
  },
  setSubmissionsStatusFilter(state, value) {
    state.submissionsStatusFilter = value;
  },
  setSubmissionsProductAddOnFilter(state, value) {
    state.submissionsProductAddOnFilter = value;
  },
  setSubmissionsSearch(state, value) {
    state.submissionsSearch = value;
  },
  setSubmissionsSortingColumn(state, value) {
    state.submissionsSortingColumn = value;
  },
  setSubmissionsSortingDirection(state, value) {
    state.submissionsSortingDirection = value;
  },
  setSubmissionsAffiliateId(state, value) {
    state.submissionsAffiliateId = value;
  },
  setSubmissionsWillIds(state, value) {
    state.submissionsWillIds = value;
  },
  setWills(state, value) {
    state.wills = value;
  },
};
