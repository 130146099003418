export default () => ({
  pageIndex: 0,
  pageSize: 10,
  filters: {
    active: true,
    featuredFive: null,
    bequestEnabled: null,
    affiliateEnabled: null,
  },
  searchQuery: '',
  sortColumn: 'Created At',
  sortDirection: 'DESC',
  sortableColumns: [
    {
      field: 'name',
      columnName: 'Name',
      defaultDirection: 'ASC',
    },
    {
      field: 'createdAt',
      columnName: 'Created At',
      defaultDirection: 'DESC',
    },
    {
      field: 'updatedAt',
      columnName: 'Updated At',
      defaultDirection: 'DESC',
    },
  ],
});
