import GET_CAUSES_BY_USER_SEGMENT from '@/graphql/queries/GetCausesByUserSegment';
import { enrichCauseData } from '@/utilities';

export default {
  async getCauses({ rootGetters, commit }) {
    if (
      !rootGetters['directory-person/userDetails']?.residentialAddress?.country
    ) {
      return;
    }

    const {
      data: { getCausesByUserSegment },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_CAUSES_BY_USER_SEGMENT,
      variables: {
        userId: rootGetters.userId,
      },
    });
    const causesWithCharityMeta = getCausesByUserSegment.map((cause) =>
      enrichCauseData(cause)
    );
    commit('setCauses', causesWithCharityMeta);
  },
};
