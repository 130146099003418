import { filterNullish } from '@/utilities';
import { enrichDirectoryPersonData } from '@/utilities/directory-person';

import GET_WILLS_QUERY from '@/graphql/queries/GetWills';

function getWillsFiltersVariables(state) {
  let hasLegalAdviceBooking;
  let hasLegalAdvice;
  let willTier;

  switch (state.submissionsProductAddOnFilter) {
    case 'WITHOUT_LEGAL_ADVICE_BOOKING':
      hasLegalAdviceBooking = false;
      hasLegalAdvice = false;
      break;
    case 'WITH_LEGAL_ADVICE_BOOKING':
      hasLegalAdviceBooking = true;
      hasLegalAdvice = false;
      break;
    case 'WITH_LEGAL_ADVICE':
      hasLegalAdvice = true;
      break;
    case 'WILL':
    case 'WILL_TIER_TWO':
      willTier = state.submissionsProductAddOnFilter;
      break;
    default:
      break;
  }

  return filterNullish({
    archived: state.submissionsStatusFilter
      ? state.submissionsStatusFilter === 'ARCHIVED'
      : undefined,
    status:
      state.submissionsStatusFilter === 'ARCHIVED'
        ? undefined
        : state.submissionsStatusFilter,
    hasLegalAdviceBooking,
    hasLegalAdvice,
    willTier,
    affiliateId: state.submissionsAffiliateId ?? undefined,
  });
}

export default {
  async setSubmissionsPageIndex({ commit, dispatch }, value) {
    commit('setSubmissionsPageIndex', value);
    await dispatch('refetchWills');
  },
  async nextSubmissionsPage({ dispatch, state }) {
    await dispatch('setSubmissionsPageIndex', state.submissionsPageIndex + 1);
  },
  async prevSubmissionsPage({ dispatch, state }) {
    await dispatch('setSubmissionsPageIndex', state.submissionsPageIndex - 1);
  },
  async setSubmissionsStatusFilter({ dispatch, commit }, value) {
    commit('setSubmissionsStatusFilter', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsProductAddOnFilter({ dispatch, commit }, value) {
    commit('setSubmissionsProductAddOnFilter', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsSearch({ dispatch, commit }, value) {
    commit('setSubmissionsSearch', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async setSubmissionsSort({ state, commit, dispatch }, value) {
    if (state.submissionsSortingColumn === value) {
      commit(
        'setSubmissionsSortingDirection',
        state.submissionsSortingDirection === 'ASC' ? 'DESC' : 'ASC'
      );
    } else {
      commit('setSubmissionsSortingColumn', value);
    }
    await dispatch('refetchWills');
  },
  setSubmissionsWillIds({ commit }, value) {
    commit('setSubmissionsWillIds', value);
  },
  async setSubmissionsAffiliateId({ dispatch, commit }, value) {
    commit('setSubmissionsAffiliateId', value);
    await dispatch('setSubmissionsPageIndex', 0);
  },
  async refetchWills({ state, commit }) {
    const {
      data: { getWills },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_WILLS_QUERY,
      variables: {
        limit: state.pageSize,
        offset: state.submissionsPageIndex * state.pageSize,
        searchQuery: state.submissionsSearch.length
          ? state.submissionsSearch
          : null,
        customOrder: {
          field: state.submissionsSortableColumns.find(
            (column) => column.columnName === state.submissionsSortingColumn
          ).field,
          direction: state.submissionsSortingDirection,
        },
        filters: getWillsFiltersVariables(state),
      },
    });

    const enrichedWills = [];
    for (const will of getWills) {
      const enrichedWill = { ...will };
      if (enrichedWill.user?.person) {
        const enrichedPerson = await enrichDirectoryPersonData(
          enrichedWill.user.person
        );
        enrichedWill.user.person = enrichedPerson;
      }
      enrichedWills.push(enrichedWill);
    }

    commit('setWills', enrichedWills);
  },
  // Return undefined when no more 'next' values exist
  async nextWillId({ state, dispatch }, currentWillId) {
    let willIds = state.submissionsWillIds;
    const index = willIds.indexOf(currentWillId);

    if (index < 0) {
      return;
    }
    if (index < willIds.length - 2) {
      return willIds[index + 1];
    }

    const currentWillLength = willIds.length;
    for (let i = 0; i < 10 && willIds.length === currentWillLength; i++) {
      await dispatch('nextSubmissionsPage');

      const nextWillIds = state.wills.map((will) => will.id);
      if (nextWillIds.length === 0) {
        break;
      }
      willIds = [...new Set([...willIds, ...nextWillIds])];
    }

    if (willIds.length > currentWillLength) {
      dispatch('setSubmissionsWillIds', willIds);
      return willIds[index + 1];
    }

    await dispatch('prevSubmissionsPage');
  },
  // Return undefined when no more 'previous' values exist
  async previousWillId({ state, dispatch }, currentWillId) {
    let willIds = state.submissionsWillIds;
    const index = willIds.indexOf(currentWillId);

    if (index < 0) {
      return;
    }
    if (index > 0) {
      return willIds[index - 1];
    }
    const currentWillLength = willIds.length;
    for (
      let i = state.submissionsPageIndex;
      i >= 0 && willIds.length === currentWillLength;
      i--
    ) {
      await dispatch('prevSubmissionsPage');

      const previousWillIds = state.wills.map((will) => will.id);
      if (previousWillIds.length === 0) {
        break;
      }
      willIds = [...new Set([...previousWillIds, ...willIds])];
    }
    const newIndex = willIds.indexOf(currentWillId);
    if (willIds.length > currentWillLength && newIndex > 0) {
      dispatch('setSubmissionsWillIds', willIds);
      return willIds[newIndex - 1];
    }
  },
};
