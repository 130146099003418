import CREATE_DIRECTORY_PERSON from '@/graphql/mutations/CreateDirectoryPerson';
import UPDATE_DIRECTORY_PERSON from '@/graphql/mutations/UpdateDirectoryPerson';
import GET_DIRECTORY_PERSONS_BY_OWNER_ID from '@/graphql/queries/GetDirectoryPersonsByOwnerId';
import GET_DIRECTORY_PERSON from '@/graphql/queries/GetDirectoryPerson';
import DELETE_DIRECTORY_PERSON from '@/graphql/mutations/DeleteDirectoryPerson';
import VALIDATE_DELETE_DIRECTORY_PERSON from '@/graphql/queries/ValidateDeleteDirectoryPerson';

import {
  directoryPersonToApiInput,
  getPersonRelationshipWithUser,
  enrichDirectoryPersonData,
} from '@/utilities/directory-person';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';
import { isNullish } from '@/utilities';

async function updateDirectoryPerson(
  app,
  state,
  variables,
  partnershipConsent
) {
  let updatedDirectoryPerson;

  if (app.$ff.tokenisePersonData()) {
    const { $sensitive } = window.$nuxt;
    const personData = { ...variables.input };

    if (personData.residentialAddress?.id) {
      const tokenisedAddressData = await $sensitive.DirectoryAddress.update(
        state.user.ownerId,
        personData.residentialAddress.id,
        {
          ...personData.residentialAddress,
          partnershipConsent,
        }
      );
      personData.residentialAddress = tokenisedAddressData;
    }

    updatedDirectoryPerson = await $sensitive.DirectoryPerson.update(
      state.user.ownerId,
      personData.id,
      {
        ...variables,
        input: {
          ...personData,
          partnershipConsent,
        },
      }
    );
  } else {
    const {
      data: { updateDirectoryPerson },
    } = await app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_DIRECTORY_PERSON,
      variables,
    });
    updatedDirectoryPerson = updateDirectoryPerson;
  }

  if (!updatedDirectoryPerson) return;

  return await enrichDirectoryPersonData(updatedDirectoryPerson);
}

export default {
  async getDirectoryPersonData(_, id) {
    const {
      data: { getDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_DIRECTORY_PERSON,
      variables: { id },
    });

    if (getDirectoryPerson)
      return await enrichDirectoryPersonData(getDirectoryPerson);
  },
  async getDirectoryPerson({ commit, dispatch, state }, id) {
    const enrichedUser = await dispatch('getDirectoryPersonData', id);

    if (!enrichedUser) return;
    if (enrichedUser.id === state.user.id) {
      commit('setUserDetails', enrichedUser);
      dispatch('progress/setProgress', null, { root: true });
      return;
    }
    const contacts = state.contacts.filter(
      (contact) => contact.id !== enrichedUser.id
    );
    commit('setContacts', [...contacts, enrichedUser]);
  },
  async updateUserDirectoryPerson(
    { commit, dispatch, state, rootState },
    data
  ) {
    const enrichedUser = await updateDirectoryPerson(
      this.app,
      state,
      {
        input: {
          id: state.user.id,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
          residentialAddress: {
            ...(state.user.residentialAddress?.id
              ? { id: state.user.residentialAddress?.id }
              : {}),
            country: data.country,
          },
        },
      },
      rootState.will.data.meta?.notify_charities ?? false
    );

    if (!enrichedUser) return;

    const previousUserCountry = state.user.residentialAddress?.country;
    commit('setUserDetails', enrichedUser);
    dispatch('onDirectoryPersonCountryUpdate', previousUserCountry);
    dispatch('progress/setProgress', null, { root: true });
  },
  async updateDirectoryPerson({ state, commit, dispatch, rootState }, data) {
    const personInput = directoryPersonToApiInput(data);
    if (data.relationshipWithUser) {
      personInput.relationships = [
        {
          type: data.relationshipWithUser,
          fromPersonId: state.user.id,
          toPersonId: data.id,
        },
      ];
    }
    if (data.isPartnerGuardian) {
      personInput.relationships.push({
        type: RELATIONSHIP_TYPE.CHILD,
        fromPersonId: data.partnerId,
        toPersonId: data.id,
      });
    }

    const partnershipConsent =
      rootState.will.data.meta?.notify_charities ?? false;
    const enrichedUser = await updateDirectoryPerson(
      this.app,
      state,
      {
        input: personInput,
        notify: data.notifyContact,
      },
      state.user.id === personInput.id ? partnershipConsent : false
    );

    if (enrichedUser?.id === state.user.id) {
      const previousUserCountry = state.user.residentialAddress?.country;
      commit('setUserDetails', enrichedUser);
      dispatch('onDirectoryPersonCountryUpdate', previousUserCountry);
      dispatch('progress/setProgress', null, { root: true });
      return;
    }
    if (
      data.relationshipWithUser &&
      data.relationshipWithUser !==
        getPersonRelationshipWithUser(state.user, enrichedUser.id)
    ) {
      const otherRelationship =
        state.user.relationships?.filter(
          (relationship) => relationship.toPersonId !== enrichedUser.id
        ) || [];

      commit('setUserDetails', {
        ...state.user,
        relationships: [
          ...otherRelationship,
          {
            type: data.relationshipWithUser,
            fromPersonId: state.user.id,
            toPersonId: enrichedUser.id,
          },
        ],
      });
    }
    const contacts = state.contacts.filter(
      (contact) => contact.id !== enrichedUser.id
    );
    commit('setContacts', [...contacts, enrichedUser]);
    dispatch('progress/setProgress', null, { root: true });
  },
  async getDirectoryPersonsByOwnerIdData(_, { ownerId, excludeSelf }) {
    const {
      data: { getDirectoryPersonsByOwnerId },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_DIRECTORY_PERSONS_BY_OWNER_ID,
      variables: {
        ownerId,
        excludeSelf: !!excludeSelf,
      },
    });

    if (getDirectoryPersonsByOwnerId) {
      return await Promise.all(
        getDirectoryPersonsByOwnerId.map(enrichDirectoryPersonData)
      );
    }
  },
  async getDirectoryPersonsByOwnerId(
    { commit, dispatch },
    { ownerId, personId }
  ) {
    const contacts = await dispatch('getDirectoryPersonsByOwnerIdData', {
      ownerId,
      excludeSelf: !personId,
    });

    if (!contacts) return;

    if (personId) {
      const userDetails = contacts.find((person) => person.id === personId);
      if (userDetails) {
        commit('setUserDetails', userDetails);
        dispatch('progress/setProgress', null, { root: true });
      }
    }

    commit(
      'setContacts',
      contacts.filter((person) => person.id !== personId)
    );
  },
  async createDirectoryPerson({ commit, dispatch, state }, data) {
    let createdDirectoryPerson;
    const contactInput = directoryPersonToApiInput(data, state.user.id);

    if (data.relationshipWithUser) {
      contactInput.relationships = [
        {
          type: data.relationshipWithUser,
          fromPersonId: state.user.id,
        },
      ];
    }
    if (data.isPartnerGuardian) {
      contactInput.relationships.push({
        type: RELATIONSHIP_TYPE.CHILD,
        fromPersonId: data.partnerId,
      });
    }

    if (this.app.$ff.tokenisePersonData()) {
      const partnershipConsent = false;
      const { $sensitive } = window.$nuxt;
      const { residentialAddress, ...personData } = contactInput;
      const hasResidentialAddress =
        residentialAddress &&
        Object.values(residentialAddress).some((value) => !isNullish(value));
      const tokenisedResidentialAddress = hasResidentialAddress
        ? await $sensitive.DirectoryAddress.create(state.user.ownerId, {
            ...residentialAddress,
            partnershipConsent,
          })
        : residentialAddress;

      createdDirectoryPerson = await $sensitive.DirectoryPerson.create(
        state.user.ownerId,
        {
          input: {
            ...personData,
            residentialAddress: tokenisedResidentialAddress,
            partnershipConsent,
          },
          notify: data.notifyContact,
        }
      );
    } else {
      const {
        data: { createDirectoryPerson },
      } = await this.app.apolloProvider.defaultClient.mutate({
        mutation: CREATE_DIRECTORY_PERSON,
        variables: {
          input: contactInput,
          notify: data.notifyContact,
        },
      });

      createdDirectoryPerson = createDirectoryPerson;
    }

    if (!createdDirectoryPerson) return;
    const revealedContact = await enrichDirectoryPersonData(
      createdDirectoryPerson
    );

    if (revealedContact.relationships?.length) {
      const relationshipWithUserObject = revealedContact.relationships.find(
        (relationship) => relationship.fromPersonId === state.user.id
      );
      if (relationshipWithUserObject) {
        commit('upSearchUserRelationships', relationshipWithUserObject);
      }
    }
    commit('appendContact', revealedContact);
    dispatch('progress/setProgress', null, { root: true });
    return revealedContact;
  },
  async deleteDirectoryPerson({ commit, dispatch }, id) {
    const {
      data: { deleteDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: DELETE_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });
    if (deleteDirectoryPerson) {
      commit('removeContact', id);
      dispatch('progress/setProgress', null, { root: true });
    }
    return deleteDirectoryPerson;
  },
  async validateDeleteDirectoryPerson(_, id) {
    const {
      data: { validateDeleteDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: VALIDATE_DELETE_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });
    return validateDeleteDirectoryPerson;
  },
  async onDirectoryPersonCountryUpdate({ dispatch, state }, country) {
    if (state.user.residentialAddress?.country !== country) {
      await dispatch('charity-causes/getCauses', null, { root: true });
      await dispatch('product/getProducts', null, { root: true });
    }
  },
  async updatePartnershipConsent({ state }, consent) {
    if (!this.app.$ff.tokenisePersonData()) {
      return;
    }
    await updateDirectoryPerson(
      this.app,
      state,
      {
        input: {
          id: state.user.id,
          residentialAddress: {
            id: state.user.residentialAddress.id,
          },
        },
      },
      consent
    );
  },
};
