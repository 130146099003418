export default {
  pageIndex: (state) => state.pageIndex,
  pageSize: (state) => state.pageSize,
  searchQuery: (state) => state.searchQuery,
  filters: (state) => state.filters,
  sort: (state) => [state.sortColumn, state.sortDirection],
  sortableColumns: (state) =>
    state.sortableColumns.map(({ columnName }) => columnName),
  sortOrder: (state) => ({
    field: state.sortableColumns.find(
      ({ columnName }) => columnName === state.sortColumn
    ).field,
    direction: state.sortDirection,
  }),
};
