export default {
  setSubscriptionAutoRenewal(state, payload) {
    state.subscriptionAutoRenewal = payload;
  },
  setDiscountCode(state, payload) {
    state.discountCode = payload === '' ? null : payload;
  },
  setProductRemoval(state, payload) {
    state.productRemoval = payload;
  },
  setShowSubscriptionAutoRenewalInCheckout(state, payload) {
    state.showSubscriptionAutoRenewalInCheckout = payload;
  },
  setCartItemsWithPriceData(state, { cartItems, cartPriceData }) {
    state.cartItems = [...cartItems].sort((a, b) => {
      if (a.product.code === 'SUBSCRIPTION') return 1;
      if (b.product.code === 'SUBSCRIPTION') return -1;
      return 0;
    });
    state.cartPriceData = {
      basePrice: cartPriceData?.basePrice,
      finalPrice: cartPriceData?.finalPrice,
      products:
        cartPriceData?.pricedItems?.reduce(
          (acc, pricedItem) => ({
            ...acc,
            [pricedItem.product]: {
              basePrice: pricedItem.basePrice,
              finalPrice: pricedItem.finalPrice,
            },
          }),
          {}
        ) ?? {},
    };
  },
};
