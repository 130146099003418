import { getField, updateField } from 'vuex-map-fields';
import { metaArrayToObject } from '@/utilities';

function defaults(o = {}) {
  return Object.assign(
    {
      name: '',
      type: '',
    },
    o
  );
}

export const state = () => ({
  id: null,
  fields: defaults(),
  showPetPanel: false,
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  showPetPanel: (state) => state.showPetPanel,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  showPetPanel(state, value) {
    state.showPetPanel = value;
  },
  updateField,
};

export const actions = {
  addPet({ commit }) {
    commit('id', null);
    commit('fields', defaults());
    commit('showPetPanel', true);
  },
  editPet({ commit }, pet) {
    commit('id', pet.id);
    commit('fields', defaults(metaArrayToObject(pet.meta)));
    commit('showPetPanel', true);
  },
  setShowPetPanel({ commit }, value) {
    commit('showPetPanel', value);
  },
};
