export default {
  async addWillTierTwoToCart({ dispatch, commit }) {
    commit('setLoading', true);
    await dispatch(
      'cart/addToCart',
      {
        codes: ['WILL_TIER_TWO'],
        showSnackbar: false,
      },
      {
        root: true,
      }
    );

    window.$nuxt.$toast.addDefault({
      severity: 'success',
      title: this.app.i18n.t(
        'commons.willTiers.toast.upgradeToWillTierTwo.summary'
      ),
      message: this.app.i18n.t(
        'commons.willTiers.toast.upgradeToWillTierTwo.detail'
      ),
    });
    commit('setLoading', false);
  },

  async addWillToCart({ dispatch, commit }) {
    commit('setLoading', true);
    await dispatch(
      'cart/addToCart',
      {
        codes: ['WILL'],
        showSnackbar: false,
      },
      {
        root: true,
      }
    );
    window.$nuxt.$toast.addDefault({
      severity: 'success',
      title: this.app.i18n.t('commons.willTiers.toast.downgradeWill.summary'),
      message: this.app.i18n.t('commons.willTiers.toast.downgradeWill.detail'),
    });
    commit('setLoading', false);
  },
};
