export default {
  setSearchQuery({ commit, dispatch }, value) {
    commit('setSearchQuery', value);
    dispatch('resetPage');
  },
  setFilters({ commit, dispatch }, value) {
    commit('setFilters', value);
    dispatch('resetPage');
  },
  setSort({ commit, dispatch, state }, value) {
    if (state.sortColumn === value) {
      commit(
        'setSortDirection',
        state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
      );
    } else {
      commit('setSortColumn', value);
    }

    dispatch('resetPage');
  },
  nextPage({ commit, state }) {
    commit('setPageIndex', state.pageIndex + 1);
  },
  prevPage({ commit, state }) {
    commit('setPageIndex', state.pageIndex - 1);
  },
  resetPage({ commit }) {
    commit('setPageIndex', 0);
  },
};
