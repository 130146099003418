export default {
  wills: (state) => state.wills,
  pageSize: (state) => state.pageSize,
  submissionsPageIndex: (state) => state.submissionsPageIndex,
  submissionsStatusFilter: (state) => state.submissionsStatusFilter,
  submissionsProductAddOnFilter: (state) => state.submissionsProductAddOnFilter,
  submissionsSearch: (state) => state.submissionsSearch,
  submissionsSortingDirection: (state) => state.submissionsSortingDirection,
  submissionsSort: (state) => [
    state.submissionsSortingColumn,
    state.submissionsSortingDirection,
  ],
  submissionsSortableColumns: (state) =>
    Object.values(state.submissionsSortableColumns).map(
      (field) => field.columnName
    ),
  submissionsSortableFields: (state) =>
    state.submissionsSortableColumns.reduce((result, column) => {
      result[column.columnName] = column.field;
      return result;
    }, {}),
  submissionsWillIds: (state) => state.submissionsWillIds,
  submissionsAffiliateId: (state) => state.submissionsAffiliateId,
};
