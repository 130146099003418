import { getField } from 'vuex-map-fields';
import { ONBOARDING_STEP, COUNTRY, TOOL_STATUS } from '@/utilities/constants';

export default {
  onboardingNeedsAssessmentPagesRequired: (
    state,
    _getters,
    rootState,
    rootGetters
  ) => {
    const userHasCountryAndIsAustralian =
      rootGetters['directory-person/userDetails']?.residentialAddress
        ?.country === COUNTRY.AU || state.payload.country === COUNTRY.AU;
    return (
      userHasCountryAndIsAustralian &&
      rootState.featureFlags['onboarding-needs-assessment'] &&
      rootGetters['affiliate/features'].onboardingNeedsAssessmentEnabled
    );
  },
  stepKeys: (_state, getters) => {
    return [
      ONBOARDING_STEP.NAME,
      ONBOARDING_STEP.DOB,
      ONBOARDING_STEP.COUNTRY,
      ...(getters.onboardingNeedsAssessmentPagesRequired
        ? [ONBOARDING_STEP.FAMILY, ONBOARDING_STEP.ASSETS]
        : []),
    ];
  },
  steps: (state, getters) => {
    return getters.stepKeys.reduce((acc, key) => {
      acc[key] = state.steps[key];
      return acc;
    }, {});
  },
  nextStepKey: (state, getters) => {
    const currentIndex = getters.stepKeys.indexOf(state.currentStepKey);
    return currentIndex === getters.stepKeys.length - 1
      ? null
      : getters.stepKeys[currentIndex + 1];
  },
  currentStepKey: (state) => state.currentStepKey,
  currentStep: (state, getters) => {
    const currentStepIndex = getters.stepKeys.indexOf(state.currentStepKey);
    return currentStepIndex === -1 ? 0 : currentStepIndex;
  },
  currentStepData: (state) => state.steps[state.currentStepKey],
  payload: (state) => state.payload,
  totalStepsCount: (_state, getters) => getters.stepKeys.length,
  progress: (_state, getters) => {
    return (getters.currentStep + 1) / getters.stepKeys.length;
  },
  progressBarIsVisible: (state) => {
    return [
      ONBOARDING_STEP.NAME,
      ONBOARDING_STEP.DOB,
      ONBOARDING_STEP.COUNTRY,
      ONBOARDING_STEP.FAMILY,
      ONBOARDING_STEP.ASSETS,
    ].includes(state.currentStepKey);
  },
  hasCompletedVaultItemStep:
    (state, _getters, _rootState, rootGetters) => (stepKey) => {
      const onboardingNeedsAssessmentTool =
        rootGetters['tool/onboardingNeedsAssessmentTool'];
      if (onboardingNeedsAssessmentTool.status === TOOL_STATUS.COMPLETED)
        return true;
      const vaultItems =
        rootGetters['vault-item/onboardingNeedsAssessmentVaultItems'];
      if (!vaultItems?.length) return false;
      const stepVaultItems = state.steps[stepKey].vaultItems;
      return stepVaultItems.some((stepVaultItem) =>
        vaultItems.some(
          (vaultItem) =>
            vaultItem.key === stepVaultItem.key &&
            vaultItem.type === stepVaultItem.type
        )
      );
    },
  loading: (state) => state.loading,
  selectedVaultItemsIds: (state) => state.selectedVaultItemsIds,
  selectedVaultItemsCount: (state, getters, rootState, rootGetters) => {
    return rootGetters['vault-item/onboardingNeedsAssessmentVaultItems'].filter(
      ({ value }) => value
    ).length;
  },
  getField,
};
