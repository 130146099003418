import { createHelpers } from 'vuex-map-fields';
import { filterNullish, metaArrayToObject } from '@/utilities';
import GET_COUPONS from '@/graphql/queries/GetCoupons';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

const { getCouponField, updateCouponField } = createHelpers({
  getterType: 'getCouponField',
  mutationType: 'updateCouponField',
});

function defaults(o = {}) {
  return Object.assign(
    {
      code: '',
      type: 'PERCENTAGE',
      description: '',
      factor: 0,
      active: false,
      products: [],
    },
    o
  );
}

export const state = () => ({
  fields: defaults(),
  searchQuery: '',
  pageIndex: 0,
  pageSize: 10,
  filters: {
    active: true,
  },
  coupons: [],
  partnershipOptions: [],
});

export const getters = {
  fields: (state) => state.fields,
  getCouponField,
  pageIndex: (state) => state.pageIndex,
  searchQuery: (state) => state.searchQuery,
  filters: (state) => state.filters,
  coupons: (state) => state.coupons,
  partnershipOptions: (state) => state.partnershipOptions,
};

export const mutations = {
  fields(state, value) {
    state.fields = value;
  },
  updateCouponField(state, field) {
    if (field.path === 'fields.factor') {
      field.value = parseInt(field.value) || 0;
    }
    if (field.path === 'code') {
      field.code = field.code.toUpperCase();
    }
    updateCouponField(state, field);
  },
  decrementPageIndex(state) {
    state.pageIndex--;
  },
  incrementPageIndex(state) {
    state.pageIndex++;
  },
  pageIndex(state, value) {
    state.pageIndex = value;
  },
  setSearchQuery(state, value) {
    state.searchQuery = value;
  },
  setFilters(state, value) {
    state.filters = value;
  },
  setCoupons(state, value) {
    state.coupons = value;
  },
  setPartnershipOptions(state, value) {
    state.partnershipOptions = value;
  },
};

export const actions = {
  nextPage({ commit }) {
    commit('incrementPageIndex');
  },
  previousPage({ commit }) {
    commit('decrementPageIndex');
  },
  resetPageIndex({ commit }) {
    commit('pageIndex', 0);
  },
  setSearchQuery({ commit }, value) {
    commit('setSearchQuery', value);
  },
  setFilters({ commit }, value) {
    commit('setFilters', value);
  },
  async refetchCoupons({ commit, state }) {
    const {
      data: { getCoupons },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_COUPONS,
      fetchPolicy: 'no-cache',
      variables: {
        limit: state.pageSize,
        offset: state.pageSize * state.pageIndex,
        searchQuery: state.searchQuery,
        filters: filterNullish(state.filters),
      },
    });

    if (getCoupons) {
      getCoupons.forEach((coupon) => {
        coupon.meta = metaArrayToObject(coupon.meta);
      });
      commit('setCoupons', getCoupons);
    }
  },
  async refetchPartnershipOptions({ commit }) {
    const {
      data: { getPartnerships },
    } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PARTNERSHIPS_QUERY,
    });

    if (getPartnerships) {
      commit('setPartnershipOptions', [
        {
          value: null,
          text: 'All',
        },
        ...getPartnerships.map((partnership) => ({
          value: partnership.id,
          text: partnership.name,
        })),
      ]);
    }
  },
};
