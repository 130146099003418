const AU_STATE = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const AU_STATE_NAME = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

export default (_ctx, inject) => {
  const stateCodeToStateName = (stateCode) => {
    return AU_STATE_NAME[stateCode.toUpperCase()];
  };

  const auStates = [
    AU_STATE.ACT,
    AU_STATE.NSW,
    AU_STATE.NT,
    AU_STATE.QLD,
    AU_STATE.SA,
    AU_STATE.TAS,
    AU_STATE.VIC,
    AU_STATE.WA,
  ];

  const auStatesOptions = auStates.map((australianState) => {
    return {
      text: stateCodeToStateName(australianState),
      value: australianState,
    };
  });

  inject('auStates', auStates);
  inject('auStatesOptions', auStatesOptions);
};
