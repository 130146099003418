import { saveAs } from 'file-saver';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

async function getPartnerships(apollo, variables = {}) {
  const { data } = await apollo.defaultClient.query({
    query: GET_PARTNERSHIPS_QUERY,
    fetchPolicy: 'no-cache',
    variables,
  });

  return data.getPartnerships;
}

export const state = () => ({
  showNewPartnershipModal: false,
});

export const getters = {
  showNewPartnershipModal: (state) => state.showNewPartnershipModal,
};

export const mutations = {
  setShowNewPartnershipModal(state, value) {
    state.showNewPartnershipModal = value;
  },
};

export const actions = {
  setShowNewPartnershipModal({ commit }, value) {
    commit('setShowNewPartnershipModal', value);
  },
  async downloadCsv() {
    try {
      const partnerships = await getPartnerships(this.app.apolloProvider);
      const fields = [
        'Name',
        'Categories',
        'Active',
        'Active Coupons',
        'Country',
        'State',
        'Featured Five',
      ];

      const blob = new Blob(
        [
          `${fields.join(',')}\n`,
          ...partnerships.map((partnership) => {
            let csvString = '';
            csvString += `"${partnership.name}",`;
            csvString += `"${partnership.categories.join('\n')}",`;
            csvString += `"${partnership.active}",`;
            csvString += `"${partnership.activeCouponCodes.join('\n')}",`;
            csvString += `"${partnership.country || ''}",`;
            csvString += `"${partnership.region || ''}",`;
            csvString += `"${partnership.featuredFive}"`;
            csvString += '\n';

            return csvString;
          }),
        ],
        {
          type: 'text/csv;charset=utf-8',
        }
      );
      saveAs(blob, `partnerships-list-${Date.now()}.csv`);
    } catch (error) {
      console.error('Error downloading CSV', error);

      window.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'error',
        text: 'Failed to download partnerships list',
      });
    }
  },
};
