export default {
  setPageIndex(state, value) {
    state.pageIndex = value < 0 ? 0 : value;
  },
  setSearchQuery(state, value) {
    state.searchQuery = value;
  },
  setSortColumn(state, value) {
    state.sortColumn = value;
  },
  setSortDirection(state, value) {
    state.sortDirection = value;
  },
  setFilters(state, value) {
    state.filters = {
      ...state.filters,
      ...value,
    };
  },
};
