import MASQUERADE from '@/graphql/mutations/Masquerade';
import GET_POA_BY_USER_ID from '@/graphql/queries/GetPoaByUserId';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export const state = () => ({
  usersPageIndex: 0,
  usersPageSearch: '',

  userRoleFilter: null,
  institutionsPageIndex: 0,
  institutionsSearchQuery: '',
  endOfLifeCategoriesPageIndex: 0,
});

export const getters = {
  usersPageIndex: (state) => state.usersPageIndex,
  usersPageSearch: (state) => state.usersPageSearch,
  userRoleFilter: (state) => state.userRoleFilter,

  institutionsPageIndex: (state) => state.institutionsPageIndex,
  institutionsSearchQuery: (state) => state.institutionsSearchQuery,

  endOfLifeCategoriesPageIndex: (state) => state.endOfLifeCategoriesPageIndex,
};

export const mutations = {
  incrementUsersPageIndex(state) {
    state.usersPageIndex++;
  },
  decrementUsersPageIndex(state) {
    state.usersPageIndex--;
  },
  setUsersPageIndex(state, value) {
    state.usersPageIndex = value;
  },
  setUsersPageSearch(state, value) {
    state.usersPageSearch = value;
  },
  userRoleFilter(state, value) {
    state.userRoleFilter = value;
  },
  setInstitutionsSearchQuery(state, value) {
    state.institutionsSearchQuery = value;
  },
  setInstitutionsPageIndex(state, value) {
    state.institutionsPageIndex = value;
  },
  setEndOfLifeCategoriesPageIndex(state, value) {
    state.endOfLifeCategoriesPageIndex = value;
  },
};

export const actions = {
  nextUsersPage({ commit }) {
    commit('incrementUsersPageIndex');
  },
  prevUsersPage({ commit }) {
    commit('decrementUsersPageIndex');
  },
  resetUsersPageIndex({ commit }) {
    commit('setUsersPageIndex', 0);
  },
  setUsersPageSearch({ commit }, value) {
    commit('setUsersPageSearch', value);
  },
  setUserRoleFilter({ commit }, value) {
    commit('userRoleFilter', value);
  },
  setInstitutionsSearchQuery({ commit }, value) {
    commit('setInstitutionsSearchQuery', value);
  },
  nextInstitutionsPage({ commit, state }) {
    commit('setInstitutionsPageIndex', state.institutionsPageIndex + 1);
  },
  prevInstitutionsPage({ commit, state }) {
    commit('setInstitutionsPageIndex', state.institutionsPageIndex - 1);
  },
  resetInstitutionsPageIndex({ commit }) {
    commit('setInstitutionsPageIndex', 0);
  },
  nextEndOfLifeCategoriesPage({ commit, state }) {
    commit(
      'setEndOfLifeCategoriesPageIndex',
      state.endOfLifeCategoriesPageIndex + 1
    );
  },
  prevEndOfLifeCategoriesPage({ commit, state }) {
    commit(
      'setEndOfLifeCategoriesPageIndex',
      state.endOfLifeCategoriesPageIndex - 1
    );
  },
  async masquerade({ dispatch }, { userId, willId, willStatus }) {
    try {
      const { data } = await this.app.apolloProvider.defaultClient.mutate({
        mutation: MASQUERADE,
        variables: {
          id: userId,
        },
      });

      const {
        data: { poaByUserId },
      } = await this.app.apolloProvider.defaultClient.query({
        query: GET_POA_BY_USER_ID,
        variables: {
          userId,
        },
      });
      if (poaByUserId) {
        dispatch('poa/setPoaId', poaByUserId.id, { root: true });
        dispatch('poa/getAllPoaRelatedData', null, { root: true });
      }

      window.$nuxt.$sensitive.DirectoryAddress.setMasqueradeUserId(
        data.masquerade.user.id
      );
      window.$nuxt.$sensitive.DirectoryPerson.setMasqueradeUserId(
        data.masquerade.user.id
      );

      for (const key in window.$nuxt.$sensitive.EndOfLifeItem) {
        window.$nuxt.$sensitive.EndOfLifeItem[key].setMasqueradeUserId(
          data.masquerade.user.id
        );
      }

      dispatch('setMasquerading', true, { root: true });
      dispatch('setEmail', data.masquerade.user.email, { root: true });
      dispatch('setUserId', data.masquerade.user.id, { root: true });
      dispatch('setVerified', data.masquerade.user.verified, { root: true });
      dispatch('setWillId', willId, { root: true });
      dispatch('setWillStatus', willStatus, { root: true });

      await dispatch('product-prices/getProductPrices', null, { root: true });
      await dispatch('product/getProducts', null, { root: true });
      await dispatch('coupon/getCouponLatestReferral', null, { root: true });
      await dispatch('orders/getOrders', null, { root: true });
      await dispatch('cart/setDiscountCode', null, { root: true });

      await dispatch(
        'directory-person/getDirectoryPersonsByOwnerId',
        {
          ownerId: data.masquerade.user.id,
          personId: data.masquerade.user?.person_id,
        },
        { root: true }
      );
      await dispatch('flag/getPlanFlags', { planId: willId }, { root: true });
      await dispatch('charity-causes/getCauses', null, { root: true });
      this.$router.push({ path: this.localePath('/') });
    } catch (e) {
      console.error(e.message);
    }
  },
  async exitMasquerade({ dispatch }) {
    try {
      const { data } = await this.app.apolloProvider.defaultClient.query({
        query: CURRENT_USER_QUERY,
      });

      dispatch('setMasquerading', false, { root: true });
      dispatch('setEmail', data.currentUser.user.email, { root: true });
      dispatch('setUserId', data.currentUser.user.id, { root: true });
      dispatch('setVerified', data.currentUser.user.verified, { root: true });

      window.$nuxt.$sensitive.DirectoryAddress.setMasqueradeUserId(null);
      window.$nuxt.$sensitive.DirectoryPerson.setMasqueradeUserId(null);

      for (const key in window.$nuxt.$sensitive.EndOfLifeItem) {
        window.$nuxt.$sensitive.EndOfLifeItem[key].setMasqueradeUserId(null);
      }
      this.$router.push({ path: this.localePath('/admin') });
    } catch (e) {
      console.error(e.message);
    }
  },
};
