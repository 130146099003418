export default () => ({
  wills: [],
  pageSize: 10,
  submissionsPageIndex: 0,
  submissionsStatusFilter: null,
  submissionsProductAddOnFilter: '',
  submissionsSearch: '',
  submissionsSortingColumn: 'Updated',
  submissionsSortingDirection: 'ASC',
  submissionsSortableColumns: [
    {
      field: 'hashId',
      columnName: 'ID',
      defaultDirection: 'ASC',
    },
    {
      field: 'status',
      columnName: 'Status',
      defaultDirection: 'ASC',
    },
    {
      field: 'updatedAt',
      columnName: 'Updated',
      defaultDirection: 'ASC',
    },
  ],
  submissionsAffiliateId: undefined,
  submissionsWillIds: [],
});
